/* mobile first layout */
.title-container {
    margin-top: 3%;
}

.education-container {
    grid-column: 2/3;
}
.section-title {
    display: flex;
    justify-content: center;
    margin-right: 10%;
    color: white;
    text-shadow: 1px 1px 2px rgb(255, 255, 255);
    font-family: Arial, Helvetica, sans-serif;
}

.education-tile {
    border-radius: 5px;
    padding: 10px;
    margin-left: 5%;
    margin-top: 15px;
    width: 85%;
    background-color: rgba(195, 190, 202, 0.7);
    box-shadow: 2px 5px 3px #ababab;
}

.school-name {
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    text-shadow: 1px 1px 2px rgb(255, 255, 255);
    font-family: Arial, Helvetica, sans-serif;
}

.date-range {
    border-radius: 5px;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    background-color: #046dff;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    box-shadow: 2px 5px 5px #313131;
    width: 50%;
    color: white;
}

.education-body {
    margin-left: 15px;
    margin-right: 15px;
}

.list {
    display: grid;
    grid-template-columns: repeat(2, 0.5fr);
}

.list-item {
    list-style: none;
}

/* media queries */
@media (min-width: 1100px) {
    .title-container {
        grid-column: 2 / 3;
    }

    .education-container {
        display: flex;
    }

    .education-tile {
        width: 90%;
        height: auto;
    }
}
