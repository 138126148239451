.App {
    background-image: url("./background/gradient.jpeg");
    background-size: cover;
    background-color: #202020;
}

.nav-bar {
    display: flex;
    max-height: 70px;
}

.intro-section {
    margin-left: 10%;
    margin-right: 15%;
    min-height: 100vh;
}

.projects-section {
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.35);
    min-height: 100vh;
}

.education-section {
    min-height: 100vh;
}

.about-section {
    margin-top: 7%;
    min-height: 100vh;
    background-color: rgba(200, 200, 200, 0.15);
}

.footer-bar {
    display: flex;
    height: 75px;
    justify-content: center;
    align-items: center;
    background-color: rgba(55, 55, 55, 0.1);
}

.footer-icon {
    margin-left: 15px;
    margin-right: 15px;
}

h1 {
    color: white;
}

h2 {
    margin-left: 5%;
    font-size: 48px;
}

p {
    line-height: 1.5em;
    font-size: 20px;
    color: white;
}

#education {
    scroll-padding-top: 25px;
}

#projects {
    scroll-margin-top: 40px;
}

/* Media Queries below */
@media (min-width: 900px) {
    .App {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
    .nav-bar {
        grid-column: 1/-1;
    }
    .intro-section {
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 0.01fr 1fr;
    }

    .illustration-1 {
        grid-column: 2/3;
        grid-row: 1/3;
    }

    .intro {
        grid-column: 1/2;
    }

    .projects-section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column: 1/-1;
    }

    .education-section {
        display: grid;
        grid-column: 1/-1;
        grid-template-columns: 0.3fr 2fr 0.3fr;
    }

    .about-section {
        display: grid;
        grid-column: 1/-1;
        grid-template-columns: 0.3fr 2fr 0.3fr;
        grid-template-rows: 0.2fr 0.8fr 0.2fr;
    }
    .footer-bar {
        grid-column: 1/-1;
    }

    #projects {
        scroll-margin-top: 50px;
    }
}

@media (max-width: 1400px) {
    .intro-section {
        display: grid;
        grid-template-columns: 1fr;
    }
}
