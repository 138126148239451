.intro {
    -webkit-transform: translate3d(0, 0, 0);
}

.typing-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 200px;
    margin-right: 12px;
}

.type-wrapper {
    line-height: 2em;
    font-size: larger;
}

.social-links-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 5%;
}

.social-icon {
    display: flex;
    padding: 0px;
    font-size: 30px;
    height: 55px;
    width: 55px;
    text-decoration: none;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-left: 1%;
    margin-right: 1%;
    box-shadow: 2px 5px 5px #2d2d2d;
}

.social-icon :hover {
    opacity: 0.75;
}

.resume-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f53bff;
    border-radius: 12px;
    height: 40px;
    width: 180px;
    margin-top: 7%;
    box-shadow: 2px 5px 5px #2d2d2d;
}

.resume-link-container:hover {
    background-color: rgb(143, 128, 164);
    cursor: pointer;
}

.resume-link-container:active {
    box-shadow: 0 2px 5px #666;
    transform: translateY(2px);
}

.resume-text {
    margin-left: 12px;
    font-weight: 600;
}

.illustration-1 {
    animation: fadeIn 3s, slideIn 3s;
    -webkit-animation: fadeIn 3s, slideIn 3s;
    -moz-animation: fadeIn 3s, slideIn 3s;
    -o-animation: fadeIn 3s, slideIn 3s;
    -ms-animation: fadeIn 3s, slideIn 3s;
}

/* takes care of different browsers */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes slideIn {
    from {
        margin-left: 100%;
    }
    to {
        margin-left: 0;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes slideIn {
    0% {
        margin-left: 100%;
    }
    100% {
        margin-left: 0;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes slideIn {
    0% {
        margin-left: 100%;
    }
    100% {
        margin-left: 0;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes slideIn {
    0% {
        margin-left: 100%;
    }
    100% {
        margin-left: 0;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes slideIn {
    0% {
        margin-left: 100%;
    }
    100% {
        margin-left: 0;
    }
}

h1 {
    color: white;
    text-shadow: 1px 1px 2px rgb(255, 255, 255);
}

h2 {
    margin-left: 5%;
    font-size: 48px;
}

p {
    line-height: 1.5em;
    font-size: 20px;
    color: white;
    -webkit-transform-style: preserve-3d;
}

/* Media Queries below */
@media (min-width: 900px) {
    .typing-container {
        min-height: 125px;
        min-width: 700px;
    }
    .type-wrapper {
        line-height: 1.25em;
        font-size: larger;
    }

    .intro {
        grid-column: 1/2;
    }
    .illustration-1{
        padding-left: 10%;
    }
}
