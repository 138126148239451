.about-title-container {
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    grid-column: 2/3;
}

.profile-pic {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    box-shadow: 1px 7px 9px #2d2d2d;
}

.reach-out {
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    margin-left: 35px;
    margin-right: 35px;
    font-family: Arial, Helvetica, sans-serif;
}



@media (min-width: 1100px) {
    .about-title-container {
        margin-top: 7%;
        height: 20%;
    }
    .profile-pic {
        margin-top: 15px;
        border-radius: 50%;
        box-shadow: 1px 7px 9px #2d2d2d;
    }
    .about-text-container {
        flex-direction: row;
        margin-left: 10%;
        margin-right: 10%;
        grid-column: 2/3;
        align-items: flex-start;
        justify-content: center;
    }

    .about-text {
        margin-left: 5%;
    }

    .reach-out {
        grid-column: 2/3;
        margin-left: 10%;
        margin-right: 10%;
    }

   
}
