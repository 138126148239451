.projects-container {
    display: grid;
    gap: 15px;
    justify-content: center;
}

.project {
    background-color: rgb(54, 51, 76, 0.55);
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 375px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 2px 5px 5px #2d2d2d;
}

.project-title {
    color: white;
    font-size: x-large;
}

.project-body {
    justify-content: center;
    align-items: center;
    margin-left: 55px;
    margin-right: 55px;
}

.project-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f53bff;
    border-radius: 12px;
    height: 40px;
    width: 190px;
    margin-top: 7%;
    box-shadow: 2px 5px 5px #2d2d2d;
}

.project-link-container:hover {
    background-color: rgb(143, 128, 164);
    cursor: pointer;
}

.project-link-container:active {
    box-shadow: 0 2px 5px #666;
    transform: translateY(2px);
}

.project-link-text {
    margin-left: 12px;
    font-weight: 600;
}

.section-title {
    display: flex;
    color: white;
    text-shadow: 1px 1px 2px rgb(255, 255, 255);
    font-family: Arial, Helvetica, sans-serif;
}

@media (min-width: 1100px) {
    .illustration-2 {
        grid-column: 1/2;
    }
    .projects-container {
        grid-column: 2/3;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        justify-content: center;
        align-items: center;
    }

    .projects-page-wrapper {
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }

    .project {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        box-shadow: 2px 5px 5px #2d2d2d;
    }

    .section-title {
        padding-left: 7%;
    }
}
